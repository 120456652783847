import { createUseStyles } from 'react-jss'

import {
  ELEMENTS,
  LAYOUT
} from '~styles/vars.styles'



export default createUseStyles({
  layoutFixed: {
    flex: 1,
    overflowX: 'hidden',
    padding: `calc(1.45rem + ${ELEMENTS.HEADER.HEIGHT}px) ${LAYOUT.HORIZONTAL_PADDING}px`,
    width: '100%',
    [`@media (min-width: ${LAYOUT.MAX_WIDTH - LAYOUT.HORIZONTAL_PADDING * 2}px)`]: {
      margin: `0 auto`,
      maxWidth: LAYOUT.MAX_WIDTH,  
    }
  },
  
  layoutFull: {
    flex: 1,
    overflowX: 'hidden'
  }
})