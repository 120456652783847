/**
 * Fonts
 */
export const FONT_WEIGHT_NORMAL = 400;
export const FONT_WEIGHT_BOLD = 700;
export const FONTS = {
  BODY: {
    XS: 11,
    SM: 12,
    MD: 14,
    LG: 16,

    WEIGHT: FONT_WEIGHT_BOLD,
    FAMILY: `-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`,
    LINE_HEIGHT: '1.5em'
  }, 

  BUTTONS: {
    SM: 14,
    MD: 16,
    LG: 18,

    WEIGHT: FONT_WEIGHT_BOLD,
    FAMILY: `-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`,
    LINE_HEIGHT: '1.5em'
  }, 
  
  HEADERS: {
    XS: 11,
    SM: 18,
    MD: 24,
    LG: 42,

    WEIGHT: FONT_WEIGHT_BOLD,
    FAMILY: `-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`
  }
}