import {
  TRANSITION as TRANSITION_COMMON
} from '@theme/vars';

/**
 * Track colors specific to the homepage.  As they cotify,
 * migrate vars to the `rainedout_common` repo.
 */
export const COLORS = {
  ACCENT: '#FFF454',

  LIGHT_01: '#FFFFFF',
  LIGHT_02: '#F7F8FA',
  LIGHT_03: '#E1E9FF',

  BLUE_01: '#6988E2',
  BLUE_02: '#4268DD',
  
  BLUE_MUTED_01: '#313B4E',
  BLUE_MUTED_02: '#66738B',
  BLUE_MUTED_03: '#B6BBC3',

  GREEN_01: '#28bd56',
  GREEN_02: '#23d251',

  RED_01: '#DD2E2E',

  ORANGE_01: '#FFC107',

  PURPLE_01: '#293583'
}

export const GAPS = {
  MD: 15
}

export const FONTS = {}

export const BREAKPOINTS = {
  // Bar Big
  BAR_BIG: 850,
  
  // Shrink from the hamburger menu to full menu.
  MENU_BIG: 850,

  // Increase modal sizes.
  MODAL_BIG: 710,

  // Increase hero padding and font sizes.
  HERO_BIG: 710,

  // Increase font size 
  HERO_BUTTONS_MEDIUM: 380,

  // Spread buttons across single line
  HERO_BUTTONS_BIG: 900,

  // The phone and messages start off below the CTA.
  // Push them off to the side on larger screens.
  HERO_PHONE_SIDE: 600,

  // Adjust numbering
  FAQ_BIG: 1024,

  // Features grid
  FEATURES_GRID_TWO: 940,
  FEATURES_GRID_FOUR: 1400,

  // Plans
  PLAN_CARDS_BIG: 850,
  PLAN_CALCULATOR_BIG: 440,
  PLAN_LOGIN_OR_CHECKOUT_BUTTON_GROUP_BIG: 620,

  // Payment form
  PAYMENT_FORM_BIG: 850,

  // Why RainedOut?
  WHY_RAINEDOUT_BIG: 850,

  // Tooltip max width changes based on screen resolution
  TOOLTIP_BIG: 1024
}

export const LAYOUT = {
  MAX_WIDTH: 960,
  HORIZONTAL_PADDING: 40
}

export const ELEMENTS = {
  ALERT: {
    HEIGHT: 80
  },
  HEADER: {
    HEIGHT: 80
  },
  HERO: {
    MESSAGE: {
      ANIMATION_DURATION: TRANSITION_COMMON.DURATION_MD,
      ANIMATION_STAGGER_INTERVAL: TRANSITION_COMMON.DURATION_SM
    }
  },
}