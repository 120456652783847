/**
 * Fixed-width layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import classNames from 'classnames';
import { useStaticQuery, graphql } from "gatsby"

import Footer from "~components/footer/footer.component"
import Header from "~components/header/header.component"

import { StripeProvider } from '~components/stripe-element/stripe-element.component'

/**
 * Reset styles
 */
import "~styles/reset.css"

import {
  LAYOUT_NAME_FIXED,
  LAYOUT_NAME_FULL,
  LAYOUT_NAMES
} from '~constants';

/**
 * Component styles
 */
import useStyles from './layout.styles';

const Layout = ({ children, includeNavigation, includeAlert, layout, props }) => {
  const classes = useStyles(props);
  
  const data = useStaticQuery(graphql`
    query SiteTitleQueryLayoutArticle {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <StripeProvider>
      <>
        <Header
          includeAlert={includeAlert}
          includeNavigation={includeNavigation}
          siteTitle={data.site.siteMetadata.title}
        />
        <div className={classNames({
          [classes.layoutFixed]: layout === LAYOUT_NAME_FIXED,
          [classes.layoutFull]: layout === LAYOUT_NAME_FULL
        })}>
          <main>{children}</main>
        </div>
        <Footer includeNavigation={includeNavigation} />
      </>
    </StripeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,

  /**
   * If an alert is present, let the layout component know so it can adjust vertical offset accordingly.
   */
  includeAlert: PropTypes.bool,

  /**
   * Prior to launching, omit certain navigation elements from the site on an ad-hoc basis.
   */
  includeNavigation: PropTypes.bool,

  /**
   * Layout
   */
  layout: PropTypes.oneOf(LAYOUT_NAMES)
}

Layout.defaultProps = {
  includeAlert: false,
  includeNavigation: true,
  layout: LAYOUT_NAME_FIXED
}

export default Layout
