import { Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import PropTypes from "prop-types"
import React from "react"

import Logo from '~images/rainedout-logo.inline.svg';

import useStyles from './footer.styles';

const Footer = ({ props, includeNavigation }) => {
  const classes = useStyles(props);

  return (
    <footer className={classes.footer}>
      {includeNavigation ? 
      <div className={classes.upper}>
        <div className={classes.column}>
          <Link
            to="/"
            className={classes.logo}
          >
            <Logo />
          </Link>
        </div>
        
        <div className={classes.column}>
          <div className={classes.linkHeader}>About Us</div>
          <Link
            to="/why-rainedout"
            className={classes.link}
          >
            Why RainedOut?
          </Link>
        </div>

        <div className={classes.column}>
          <div className={classes.linkHeader}>Help</div>
          <Link
            to="/faq"
            className={classes.link}
          >
            FAQs
          </Link>
          <Link
            to="/team"
            className={classes.link}
          >
            Subscribe
          </Link>
          <OutboundLink
            href="https://www.rainedout.net/opt_out.php"
            className={classes.link}
          >
            Unsubscribe
          </OutboundLink>
        </div>

        <div className={classes.column}>
          <div className={classes.linkHeader}>Reach Us</div>
          <Link
            to="/contact"
            className={classes.link}
          >
            Contact
          </Link>
          <Link
            to="/terms"
            className={classes.link}
          >
            Terms
          </Link>
          <Link
            to="/privacy"
            className={classes.link}
          >
            Privacy
          </Link>
        </div>
      </div> : null}
      <div className={classes.lower}>
      © {new Date().getFullYear()}, RainedOut, LLC. All Rights Reserved.
      </div>
    </footer>
  )
}

Footer.propTypes = {
  includeNavigation: PropTypes.bool,
  siteTitle: PropTypes.string
}

Footer.defaultProps = {
  includeNavigation: true,
  siteTitle: ''
}

export default Footer
