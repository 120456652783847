import { createUseStyles } from 'react-jss'
import color from 'color';

import {
  COLORS,
  ELEMENTS,
  GAPS
} from '~styles/vars.styles'

import {
  link
} from '~styles/mixins.styles'

import {
  FONTS as FONTS_COMMON
} from '@theme/fonts'

export const PADDING_Y = 26;


export default createUseStyles({
  includeAlert: {
    top: ELEMENTS.ALERT.HEIGHT
  },
  /**
   * Big menu styles
   */
  headerBig: {
    display: 'flex',
    padding: [PADDING_Y, 33],
    justifyContent: 'space-between',

    position: 'absolute',
    width: '100%',
    height: ELEMENTS.HEADER.HEIGHT
  },

  logo: {
    color: COLORS.PURPLE_01,
    display: 'flex',
    alignItems: 'center'
  },
  
  links: {
    fontSize: FONTS_COMMON.HEADERS.SM,
    letterSpacing: '-0.05em',
    margin: '0 auto'
  },

  link: {
    ...link({
      color: COLORS.BLUE_02,
      fontWeight: 500
    }),
    borderRadius: 52,
    margin: [0, GAPS.MD * 2 - PADDING_Y],
    padding: [10, PADDING_Y]
  },

  linkActive: {
    boxShadow: [
      [0, 2, 16, 0, color(COLORS.ACCENT).alpha(0.25).string()],
      [0, 8, 25, 0, color(COLORS.ACCENT).alpha(0.10).string()]
    ],
    backgroundColor: COLORS.ACCENT,
    color: COLORS.PURPLE_01,
    textDecoration: 'none'
  },

  /**
   * Small menu styles
   */
  sm: {
    padding: [PADDING_Y, 33],
    position: 'absolute',
    width: '100%',
    height: ELEMENTS.HEADER.HEIGHT
  },

  smActive: {
    background: COLORS.LIGHT_02,
  },

  smIcon: {
    position: 'absolute',
    right: 33,
    top: PADDING_Y,
    color: COLORS.PURPLE_01,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center'
  },

  smLinks: {
    position: 'absolute',
    left: 0,
    right: 0,
    background: COLORS.LIGHT_02,
    zIndex: 1,
    boxShadow: `0 20px 20px ${color(COLORS.BLUE_02).alpha(0.25).string()}`
  },

  smLink: {
    ...link({
      color: COLORS.BLUE_02,
      fontWeight: 500
    }),
    display: 'block',
    padding: [20, 26],
    '&:hover': {
      background: COLORS.LIGHT_03
    }
  },

  smLinkActive: {
    backgroundColor: COLORS.ACCENT,
    color: COLORS.PURPLE_01,
    textDecoration: 'none',
    pointerEvents: 'none'
  }
})