/**
 * Keyboard code shortcuts produced by `keydown`/`keypress` events
 */
export const KEY_CODES = {
  COMMA: 188,
  DELETE: 8,
  ESC: 27,
  ENTER: 13,
  SPACE: 32,
  TAB: 9
}


/**
 * Page layouts
 */
export const LAYOUT_NAME_FIXED = 'LAYOUT_NAME_FIXED';
export const LAYOUT_NAME_FULL = 'LAYOUT_NAME_FULL';
export const LAYOUT_NAMES = [
  LAYOUT_NAME_FIXED,
  LAYOUT_NAME_FULL
]


/**
 * Store icon names
 */
export const ICON_NAMES = [
  'add',
  'arrow-right',
  'carrot-right',
  'close',
  'menu',
  'search',
  'subtract'
]


/**
 * Alert types
 */
export const ALERT_TYPE_CRITICAL = 'ALERT_TYPE_CRITICAL';
export const ALERT_TYPE_INFO = 'ALERT_TYPE_INFO';
export const ALERT_TYPE_SUCCESS = 'ALERT_TYPE_SUCCESS';
export const ALERT_TYPE_WARNING = 'ALERT_TYPE_WARNING';


/**
 * Stripe Plans
 */
export const STRIPE_PLAN_RECIPIENT_PAYS = 'STRIPE_PLAN_RECIPIENT_PAYS';
export const STRIPE_PLAN_SENDER_PAYS = 'STRIPE_PLAN_SENDER_PAYS';
export const STRIPE_PLAN_OTHER = 'STRIPE_PLAN_OTHER';


/**
 * Current account plans (passed when logging in in the redirect_url)
 */
export const PLAN_TYPE_NO_ELECTION = 0;
export const PLAN_TYPE_SENDER_PAYS = 1;
export const PLAN_TYPE_RECIPIENT_PAYS = 2;
export const PLAN_TYPE_UNLIMITED_INVOICE_MONTHLY = 3;
export const PLAN_TYPE_UNLIMITED_FOR_REAL = 4;


/**
 * Unit economics
 */
export const ANNUAL_COST_USD = 7.30;
export const UNIT_COST_USD = 0.04;


/**
 * The minimum Stripe purchase is $10.
 */
export const STRIPE_PURCHASE_MIN = 10;
export const STRIPE_MESSAGE_QTY_MIN = STRIPE_PURCHASE_MIN / UNIT_COST_USD;
export const STRIPE_MESSAGE_QTY_MAX = 1e5;


/**
 * Locale and currency
 */
export const LOCALE = 'en-US';
export const CURRENCY = 'USD';


/**
 * Promotional video URL
 */
export const PLAN_EXPLAINER_VIDEO_URL = 'https://youtu.be/2lcPfNqtYJI';


/**
 * Analytics support
 */
export const ANALYTICS = {
  CATEGORY: {
    ADMIN_LOGIN: 'Admin Login',
    PAYMENT_FORM: 'Payment Form',
    PRICING_CALCULATOR: 'Pricing Calculator'
  },
  ACTION: {
    PAYMENT_FORM_CHECKOUT_COMPLETE: 'Purchase Complete',
    PAYMENT_FORM_CHECKOUT_ERROR: 'Purchase Error',
    PAYMENT_FORM_CHECKOUT_INITIATED: 'Purchase Initiate',
    PRICING_CALCULATOR_RECIPIENT_INPUT: 'Recipient Input Edited',
    PRICING_CALCULATOR_RECIPIENT_SLIDER: 'Recipient Slider Dragged',
    PRICING_CALCULATOR_SMS_PER_RECIPIENT_INPUT: 'SMS Per Recipient Input Edited',
    PRICING_CALCULATOR_TOTAL_SMS_INPUT: 'Total SMS Input Edited'
  },
  LABEL: {
    ADMIN_LOGIN_USERNAME: 'Admin Login Username'
  },
}