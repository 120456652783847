/**
 * Use this hook as a means to bind events directly to 'document'.
 * Cleanup occurs when mounting component is unmounted.
 */

import { useEffect, useState } from 'react';

export default ({ eventName, callback }) => {
  const [ capturedEvent, setCapturedEvent ] = useState(null);

  useEffect(() => {
    const handleEvent = e => {
      // Set the event
      setCapturedEvent(e);

      // Runs after each occurence
      if (callback) callback(capturedEvent);

      // Cleanup
      setCapturedEvent(null);
    };
    document.addEventListener(eventName, handleEvent);

    return () => {
      document.removeEventListener(eventName, handleEvent);
    };
  }, [callback, capturedEvent, eventName]);

  return capturedEvent;
};
