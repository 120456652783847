/**
 * Store transition and animation values
 */
export const TRANSITION = {
  DURATION_XS: 125,
  DURATION_SM: 200,
  DURATION_MD: 400,
  DURATION_LG: 750,
  DURATION_XL: 1500,

  EASE: 'ease-in-out'
}