/**
 * Values pertaining to foundation UI elements
 */

import { TRANSITION } from './animations'

export const ELEMENTS = {
  BUTTONS: {
    XS: 'XS',
    SM: 'SM',
    MD: 'MD',
    LG: 'LG'
  },
  LOADERS: {
    ANIMATION_DELAY: TRANSITION.DURATION_MD
  },  
  LOGO: {
    HEIGHT: 62,
    WIDTH: 197
  },
  NAV: {
    WIDTH: 280
  }
}