import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useLayoutEffect, useRef, useState } from 'react'
import classNames from 'classnames'

import {
  KEY_CODES
} from '~constants'

import {
  BREAKPOINTS
} from '~styles/vars.styles'

import Logo from '~images/rainedout-logo.inline.svg';
import Icon from '~components/icon/icon.component'

import useContentSize from '~common/use-content-size.hook';
import useDocumentEvent from '~common/use-document-event.hook';
import useIsTouch from '~common/use-is-touch.hook';
import useStyles, { PADDING_Y } from './header.styles';

const MENU_SIZE_BIG = 'MENU_SIZE_BIG';
const MENU_SIZE_SMALL = 'MENU_SIZE_SMALL';

const Header = ({ props, includeAlert, includeNavigation }) => {
  const classes = useStyles(props);

  /**
   * Track reference to header element so we can monitor its size.
   */
  const headerEl = useRef(null);

  /**
   * Track whether to use the small or big screen menu.
   */
  const [menuSize, setMenuSize] = useState('');
  
  /**
   * Track small menu's active state.
   */
  const [menuActive, setMenuActive] = useState(false);

  /**
   * Stash the tag component's dimensions for future calculations.
   */
  const { width: headerWidth, height: headerHeight } = useContentSize(headerEl);

  /**
   * Is this a touch device
   */
  const isTouchDevice = useIsTouch();

  /**
   * Set menu inactive if 'ESC' key is pressed.
   * The Macbook Pro touch bar and other touch devices
   * do not register 'keypress' events reliably. Use the
   * 'keydown' event.
   */
  const keyEvent = useDocumentEvent({ eventName: 'keydown' });
  const isKey = KEY => keyEvent && keyEvent.keyCode === KEY_CODES[KEY];

  if (menuActive && isKey('ESC')) {
    setMenuActive(false);
  }

  /**
   * Clicking outside the referenced wrapper closes the dropdown.
   */
  const documentEvent = useDocumentEvent({ eventName: isTouchDevice ? 'touchstart' : 'click' });
  if (documentEvent && menuActive && !headerEl.current.contains(documentEvent.target)) {
    setMenuActive(false);
  }

  /**
   * Adjust menu treatment based on the header component's size.
   */
  useLayoutEffect(() => {
    if (headerWidth < BREAKPOINTS.MENU_BIG) {
      setMenuSize(MENU_SIZE_SMALL)
    } else if (headerWidth >= BREAKPOINTS.MENU_BIG) {
      setMenuSize(MENU_SIZE_BIG)
    }
  }, [headerWidth])

  /**
   * Larger format menu
   */
  const renderMenuBig = () => (
    <header
      className={classNames(
        [classes.headerBig], {
          [classes.includeAlert]: includeAlert
        }
      )}
      ref={headerEl}
    >
      {includeNavigation ? 
      <>
      <Link
        to="/"
        className={classes.logo}
      >
        <Logo />
      </Link>
      <div className={classes.links}>
        <Link
          to="/"
          className={classes.link}
          activeClassName={classes.linkActive}
        >
          Home
        </Link>
        <Link
          to="/why-rainedout"
          className={classes.link}
          activeClassName={classes.linkActive}
          partiallyActive={true}
        >
          Why RainedOut?
        </Link>
        <Link
          to="/pick-a-plan"
          className={classes.link}
          activeClassName={classes.linkActive}
          partiallyActive={true}
        >
          Plans
        </Link>
        <Link
          to="/faq"
          className={classes.link}
          activeClassName={classes.linkActive}
          partiallyActive={true}
        >
          Support & FAQ
        </Link>
        <Link
          to="/admin-login"
          className={classes.link}
          activeClassName={classes.linkActive}
          partiallyActive={true}
        >
          Log In
        </Link>
      </div>
      </> :
      <div className={classes.logo}>
        <Logo />
      </div>
      }
    </header>
  )

  /**
   * Smaller screen menu
   */
  const renderMenuSmall = () => (
    <header
      className={classNames(
        [classes.sm], {
          [classes.smActive]: menuActive,
          [classes.includeAlert]: includeAlert
        }
      )}
      style={{ visibility: !menuSize ? 'hidden' : null }} // If the menu size hasn't been calculated, set the menu hidden
      ref={headerEl}
    >
      {includeNavigation ?
      <>
        <Link
          to="/"
          className={classes.logo}
        >
          <Logo />
        </Link>
        <div
          className={classes.smIcon}
          onClick={event => setMenuActive(!menuActive)}
          onKeyDown={event => {
            if (event.keyCode === KEY_CODES.ENTER) {
              setMenuActive(!menuActive)
            }}
          }
          tabIndex={0}
          role="button"
        >
          <Icon name="menu" size={24} />
        </div>
      </> : 
      <div className={classes.logo}>
        <Logo />
      </div>
      }
      
      {menuActive ? 
      <div className={classes.smLinks} style={{ top: headerHeight + PADDING_Y * 2 }}>
        <Link
          to="/"
          className={classes.smLink}
        >
          Home
        </Link>
        <Link
          to="/why-rainedout"
          className={classes.smLink}
          activeClassName={classes.smLinkActive}
          partiallyActive={true}
        >
          Why RainedOut?
        </Link>
        <Link
          to="/pick-a-plan"
          className={classes.smLink}
          activeClassName={classes.smLinkActive}
          partiallyActive={true}
        >
          Plans
        </Link>
        <Link
          to="/faq"
          className={classes.smLink}
          activeClassName={classes.smLinkActive}
          partiallyActive={true}
        >
          Support & FAQ
        </Link>
        <Link
          to="/admin-login"
          className={classes.smLink}
          activeClassName={classes.smLinkActive}
          partiallyActive={true}
        >
          Log In
        </Link>
      </div> : null}
    </header>
  )

  return menuSize === MENU_SIZE_BIG ? renderMenuBig() : renderMenuSmall();
}

Header.propTypes = {
  includeAlert: PropTypes.bool,
  includeNavigation: PropTypes.bool,
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  includeAlert: false,
  includeNavigation: true,
  siteTitle: ''
}

export default Header
