import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Add from '~images/icons/add.inline.svg';
import ArrowRight from '~images/icons/arrow-right.inline.svg';
import CarrotRight from '~images/icons/carrot-right.inline.svg';
import Close from '~images/icons/close.inline.svg';
import LoaderPuff from '~images/icons/loader-puff.inline.svg';
import Menu from '~images/icons/menu.inline.svg';
import Search from '~images/icons/search.inline.svg';
import Subtract from '~images/icons/subtract.inline.svg';

import useStyles from './icon.styles';

const Icon = props => {
  const { name, rotate, size } = props;
  const classes = useStyles(props);

  return (
    <div
      className={classNames(
        [classes.icon], {
          [props.className]: !!props.className
        }
      )}
      style={size || rotate ? {
        width: size,
        height: size,
        transform: `rotate(${rotate}deg)`
      } : null}
    >
    {(() => {
        switch(name) {
          case 'add':
            return <Add />
          case 'arrow-right':
            return <ArrowRight />
          case 'carrot-right':
            return <CarrotRight />
          case 'close':
            return <Close />
          case 'loader-puff':
            return <LoaderPuff />
          case 'menu':
            return <Menu />
          case 'search':
            return <Search />
          case 'subtract':
            return <Subtract />
          default:
            return null;
        }
      })()}
    </div>
  );
}

Icon.propTypes = {
  name: PropTypes.string,
  rotate: PropTypes.number,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

Icon.defaultProps = {
  name: 'settings',
  rotate: 0,
  size: '0.85em'
}

export default Icon;
