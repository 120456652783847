/**
 * Use this hook as a means to grab the width and height of
 * an element ref and monitor it during its entire mounted lifecycle.
 */

import { useLayoutEffect, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export default (ref) => {
  const [contentRect, setContentRect] = useState(ref && ref.current ? ref.current.getBoundingClientRect() : {});
  useLayoutEffect(() => {
    const current = ref.current;
    if (!ref || !current) return;
    const observer = new ResizeObserver(entries => setContentRect(entries[0].contentRect));
    observer.observe(current);
    return () => observer.disconnect(current);
  }, [ref]);
  return contentRect;
}
