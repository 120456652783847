/**
 * Detect if the device is touch-capable
 */

import { useEffect, useState } from 'react';

export default () => {
  const [isTouchDevice, setIsTouchDevice] = useState(null);

  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');

  // Include the 'heartz' as a way to have a non matching MQ to help terminate the join https://git.io/vznFH
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');

  useEffect(() => {
    const mq = (query) => typeof window !== 'undefined' && window.matchMedia && window.matchMedia(query).matches;

    if (('ontouchstart' in window) || (window.DocumentTouch && document instanceof DocumentTouch)) { // eslint-disable-line no-undef
      setIsTouchDevice(true)
    } else {
      setIsTouchDevice(mq(query))
    }
  }, [isTouchDevice, query]);

  return isTouchDevice;
};
