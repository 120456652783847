import {createUseStyles} from 'react-jss'

export default createUseStyles({
  icon: {
    fill: 'currentColor',
    width: '1em',
    height: '1em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& svg': {
      width: '100%',
      height: '100%'
    }
  }
})