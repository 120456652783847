import { createUseStyles } from 'react-jss'

import {
  COLORS
} from '~styles/vars.styles'

import {
  FONTS as FONTS_COMMON
} from '@theme/fonts'

import lineartTexture from '~images/lineart-texture-light.svg';

export default createUseStyles({
  footer: {},

  upper: {
    backgroundImage: `url(${lineartTexture}), radial-gradient(circle at center left, ${COLORS.PURPLE_01}, ${COLORS.PURPLE_01})`,
    backgroundRepeat: 'repeat, no-repeat',
    backgroundSize: '280px, 100%',
    padding: 90,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  
  lower: {
    background: COLORS.LIGHT_03,
    color: COLORS.BLUE_02,
    fontSize: FONTS_COMMON.BODY.MD,
    padding: 30,
    textAlign: 'center'
  },

  logo: {
    color: COLORS.LIGHT_02
  },
  
  column: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    fontSize: FONTS_COMMON.BODY.LG,
    letterSpacing: '-0.05em',
    padding: 15,
  },

  linkHeader: {
    color: COLORS.LIGHT_01,
    fontWeight: 600,
    marginBottom: 14
  },

  link: {
    display: 'block',
    color: COLORS.LIGHT_03,
    textDecoration: 'none'
  }
})