import color from 'color';
import CarrotDown from '~images/icons/carrot-down.svg';

import {
  BREAKPOINTS,
  COLORS
} from '~styles/vars.styles'

export * from './mixins/stripe';

export const buttonPill = ({ fontSize = 16, maxWidth = 400 }) => {
  return {
    display: 'flex',
    maxWidth,
    '& > *': {
      flex: 1,
      fontSize
    }
  }
}

export const buttonSmall = {
  letterSpacing: '-0.03777em',
  cursor: 'pointer',
  fontSize: 22,
  borderRadius: 6,
  padding: [20, 30]
}

export const buttonMedium = {
  letterSpacing: '-0.03777em',
  cursor: 'pointer',
  fontSize: 28,
  borderRadius: 50,
  padding: [15, 30]
}

export const link = ({ color: fontColor, fontWeight = 600, fontSize = 18 }) => {
  return {
    color: fontColor,
    cursor: 'pointer',
    fontSize: fontSize,
    fontWeight: fontWeight,
    letterSpacing: '-0.045em',
    lineHeight: '1.5em',
    textDecoration: 'none',
    '&:hover': {
      color: color(fontColor).darken(0.1).string()
    }
  }
}

export const label = {
  display: 'block',
  lineHeight: 1.5
}

export const inputLegacy = (shrink = true) => {
  return {
    width: '100%',
    [`@media (min-width: ${BREAKPOINTS.BAR_BIG}px)`]: {
      width: shrink ? 'auto' : null
    }
  }
}

export const input = {
  appearance: 'none',
  background: '#f4f6f8',
  border: 'none',
  borderRadius: 6,
  boxShadow: `inset 0 2px 3px 1px ${color(COLORS.BLUE_MUTED_03).alpha(0.15).string()}`,
  color: COLORS.BLUE_MUTED_01,
  fontFamily: 'var(--font-family)',
  letterSpacing: '-0.03777em',
  outline: 'none',
  padding: [15, 20],
  '&:focus': {
    boxShadow: `inset 0 0 0 3px ${COLORS.BLUE_02}`
  },
  '&::placeholder': {
    color: COLORS.BLUE_MUTED_02,
    fontWeight: 400
  }
}

export const textarea = {
  ...input
}

export const select = {
  ...input,
  backgroundImage: `url(${CarrotDown})`,
  backgroundRepeat: 'no-repeat',
	backgroundPosition: 'right 20px top 50%',
  backgroundSize: '.65em auto',
  paddingRight: 60
}

export const rangeThumb = {
  height: '42px',
  width: '42px',
  borderRadius: 6,
  backgroundColor: '#FFF',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: `0px 2px 6px ${color(COLORS.BLUE_02).lighten(0.6).string()}`
}

export const rangeThumbInner = {
  height: '16px',
  width: '5px',
  borderRadius: 2
}

export const rangeTrack = {
  height: '44px',
  display: 'flex',
  width: '100%'
}

export const rangeTrackInner = {
  height: '5px',
  width: '100%',
  borderRadius: '4px',
  alignSelf: 'center',
}

export const requiredLegacy = {
  position: 'relative',

  '& .field_required': {
    position: 'absolute',
    top: 0,
    left: -15,
    color: COLORS.RED_01
  }
}

export const uppercaseLabel = {
  textTransform: 'uppercase',
  color: COLORS.BLUE_MUTED_03,
  fontSize: 12,
  letterSpacing: 2,
  fontWeight: 400,
  whiteSpace: 'nowrap',
}

export const bar = {
  alignItems: 'center',
  display: 'flex',
  height: 100,
  justifyContent: 'center',
  width: '100%',

  [`@media (min-width: ${BREAKPOINTS.BAR_BIG}px)`]: {
    height: 120,
  }
}

export const badge = {
  textTransform: 'uppercase',
  background: COLORS.GREEN_01,
  fontSize: 12,
  fontWeight: 600,
  color: COLORS.LIGHT_01,
  borderRadius: 3,
  padding: [3, 7],
  marginLeft: 10
}

export const borderSeparator = location => {
  switch (location) {
    case 'top':
      return {
        borderTop: [1, 'solid', COLORS.LIGHT_03],
        marginTop: 30,
        paddingTop: 30
      }
    case 'bottom':
          default:
      return {
        borderBottom: [1, 'solid', COLORS.LIGHT_03],
        marginBottom: 30,
        paddingBottom: 30
      }
  }
}

export const tooltip = {
  maxWidth: '50vw',
  [`@media (min-width: ${BREAKPOINTS.TOOLTIP_BIG}px)`]: {
    maxWidth: '30vw',
  }
}

export const textTitle = {
  color: COLORS.PURPLE_01,
  fontSize: 40,
  fontWeight: 600,
  letterSpacing: '-0.045em',
  lineHeight: 1.0461,
  marginBottom: 30,
  textAlign: 'center'
}