import color from 'color';

import {
  input
} from '~styles/mixins.styles'

import {
  COLORS
} from '~styles/vars.styles'

  // The resulting iframed credit card element can be styled with javascript
  // See https://github.com/stripe/react-stripe-elements#demo
  // Useage: `<CardElement {...this.createOptions({ fontSize: 18 })} />`
  export const createOptions = ({ fontSize = '18px', padding = '10px' }) => {
    return {
      style: {
        base: {
          ...input,
          fontSize,
          padding,
          fontFamily: `'Poppins', 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif`,
          '::placeholder': {
            color: color(COLORS.BLUE_MUTED_02).alpha(0.5).string(),
            fontWeight: 400
          }
        },
        invalid: {
          color: '#fc2234',
        },
      },
    };
  };
